<template>
    <div class="apply-for-trial-form cn" :class="formType">
        <!-- 纷享销客说明文档！！！apps/main/components/Modal/helpFXXK.jpg -->
        <AForm
            ref="questionForm"
            :model="formState.user"
            name="apply-for-trial"
            :rules="rules"
            :validate-messages="validateMessages"
            :layout="formType === 'mobile' ? 'vertical' : 'horizontal'"
            :label-col="formState.labelCol"
            :wrapper-col="formState.wrapperCol"
        >
            <div class="scroll-y-container">
                <AFormItem
                    v-for="(formItem, index) in filteredFormItems"
                    :key="formItem.key"
                    :name="formItem.key"
                    :label="$t(formItem.t)"
                >
                    <ASelect
                        v-if="formItem.inputType === 'selector'"
                        v-model:value="formState.user.question_type"
                        :placeholder="$t(formItem.tPlaceholder)"
                        :get-popup-container="(triggerNode) => triggerNode.parentNode"
                    >
                        <ASelectOption
                            v-for="questionType in fsYxtQuestionTypeList"
                            :key="questionType.cid"
                            :value="questionType.cid"
                        >
                            {{ questionType.name }}
                        </ASelectOption>
                    </ASelect>
                    <AInput
                        v-if="formItem.inputType === 'text'"
                        v-model:value="formState.user[formItem.key]"
                        :placeholder="$t(formItem.tPlaceholder)"
                        :type="formItem.key === 'code' ? 'number' : 'text'"
                        :maxlength="99"
                        autocomplete="off"
                    />
                </AFormItem>
            </div>
            <div class="fixed-bottom-container">
                <AFormItem name="policyConfirm">
                    <ACheckbox v-model:checked="formState.policyConfirm">
                        <div class="policy-confirm-checkbox-text">
                            <span>{{ $t('global_home_header_form_conform_text') }}</span>
                            <CommonComponentsLink
                                :link-text="'global_home_header_form_conform_policy'"
                                @click.prevent="openPolicyFile('privacy')"
                            />
                        </div>
                    </ACheckbox>
                </AFormItem>
                <AFormItem class="apply-for-trial-form-submit-btn" :wrapper-col="{ span: 24 }">
                    <CommonComponentsButtonLine
                        ref="submitBtn"
                        transparent
                        light
                        :button-text="t('global_question_button_1')"
                        :disabled="!formState.isFinished"
                        @click="onSubmit"
                    />
                </AFormItem>
            </div>
        </AForm>
    </div>
</template>

<script lang="ts" setup>
    import { useRouter } from 'vue-router';
    import Http from '@tools/https';
    import { ref, onMounted, watch, reactive } from 'vue';
    import type { Rule, FormInstance } from 'ant-design-vue/es/form';
    import { gsap, Timeline } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { openPolicyFile } from '~~/composables/usePolicyFile.ts';
    import i18n from '@/locales';
    import message from '~~/antd//message/adapter';

    gsap.registerPlugin(ScrollTrigger);
    const { t } = i18n.global;
    const router = useRouter();

    const props = withDefaults(
        defineProps<{
            formType?: 'dialog' | 'mobile';
        }>(),
        { formType: 'dialog' },
    );

    const isFsYxtNormal = ref<boolean>(false); // 三方纷享客是否正常加载
    let fsYxtFormId = ''; // 三方纷享客表单id
    const submitBtn = ref<HTMLButtonElement | null>(null);
    const isSubmitting = ref<boolean>(false);

    const fsYxtQuestionTypeList = [
        { cid: '1', name: '产品问题/BUG' },
        { cid: '2', name: '优化建议' },
    ];

    const emits = defineEmits(['modalCancel']);

    // ant-vue-design 校验信息模版，https://github.com/vueComponent/ant-design-vue/blob/main/components/form/utils/messages.ts
    /* eslint-disable  */
    const validateMessages = reactive({
        required: () => t('global_validate_message_0') + '${label}!',
    });
    /* eslint-enable */
    const validatePhone = async (_rule: Rule, value: string) => {
        if (value === '') {
            return Promise.reject(t('global_validate_message_1'));
        } else if (/^1[3456789]\d{9}$/.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject(t('global_validate_message_2'));
        }
    };

    // 表单信息
    const formState = reactive<{
        createData: {
            question_type: never[];
        };
        labelCol: {
            span: number;
        };
        wrapperCol: {
            span: number;
        };
        user: {
            question_type: number;
            question_desc: string;
            company_name: string;
            job_type: string;
            phone: string;
            name: string;
        };
        policyConfirm: boolean;
        isFinished: boolean;
        formConfig: Array<{
            key: 'question_type' | 'question_desc' | 'company_name' | 'job_type' | 'phone' | 'name';
            dataType: string;
            inputType: string;
            name: string;
            t: string;
            tPlaceholder: string;
        }>;
    }>({
        createData: { question_type: [] }, // 异步数据
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
        user: {
            name: '',
            phone: '',
            company_name: '',
        },
        // companyTypeOption: [],
        policyConfirm: false,
        isFinished: false,
        // inputType 区分页面上输入框的样式
        formConfig: [
            {
                key: 'question_type',
                dataType: 'text',
                inputType: 'selector',
                name: '问题类型',
                t: 'global_home_question_form_key_1',
                tPlaceholder: 'global_home_question_form_placeholder_1',
            },
            {
                key: 'question_desc',
                dataType: 'text',
                inputType: 'text',
                name: '具体问题描述',
                t: 'global_home_question_form_key_2',
                tPlaceholder: 'global_home_question_form_placeholder_2',
            },
            {
                key: 'company_name',
                dataType: 'text',
                inputType: 'text',
                name: '公司名称',
                t: 'global_home_question_form_key_3',
                tPlaceholder: 'global_home_question_form_placeholder_3',
            },
            {
                key: 'job_type',
                dataType: 'text',
                inputType: 'text',
                name: '职位',
                t: 'global_home_question_form_key_4',
                tPlaceholder: 'global_home_question_form_placeholder_4',
            },
            {
                key: 'phone',
                dataType: 'tel',
                inputType: 'text',
                name: '联系',
                t: 'global_home_question_form_key_5',
                tPlaceholder: 'global_home_question_form_placeholder_5',
            },
            {
                key: 'name',
                dataType: 'string',
                inputType: 'text',
                name: '称呼',
                t: 'global_home_question_form_key_6',
                tPlaceholder: 'global_home_question_form_placeholder_6',
            },
        ],
    });

    // 区号为86时需要通过手机验证
    const isChinese = computed(() => {
        return formState.user.tel_code === 86;
    });
    const filteredFormItems = computed(() => {
        console.log('filteredFormItems', filteredFormItems);

        if (!isChinese.value) {
            return formState.formConfig.filter((item) => {
                return item.key !== 'code';
            });
        } else {
            return formState.formConfig;
        }
    });

    // 校验规则，设置是否必需
    const rules: Record<string, Rule[]> = {
        name: [{ required: true, type: 'string', trigger: 'blur' }],
        phone: [{ required: true, type: 'string', validator: validatePhone, trigger: ['blur'] }],
        company_name: [{ required: true, type: 'string', trigger: 'blur' }],
        job_type: [{ required: true, type: 'string', trigger: 'blur' }],
        question_type: [{ required: true, type: 'string', trigger: 'blur' }],
        question_desc: [{ required: true, type: 'string', trigger: 'blur' }],
    };

    // 表单提交回调
    const onSubmit = () => {
        questionForm.value.validate().then(async () => {
            console.log('onSubmit');
            if (isSubmitting.value) return;
            isSubmitting.value = true;
            const { question_type, question_desc, company_name, job_type, phone, name } = formState.user;
            if (window.FsYxt && isFsYxtNormal.value) {
                // 走纷享客
                const singleChoice: Record<string, any> = {
                    text7_a827472c2bd30503: question_type,
                };
                const singleLineText: Record<string, any> = {
                    text5_f4352a3d17a5efee: question_desc,
                };
                window.FsYxt.FormSDK.submitForm({
                    formId: fsYxtFormId,
                    data: {
                        singleChoice,
                        singleLineText,
                        companyName: company_name,
                        position: job_type,
                        phone,
                        name,
                    },

                    success: submitSuccess,
                    fail: (res) => {
                        message.error(res);
                        isSubmitting.value = false;
                    },
                });

                console.log(
                    'singleChoice,singleLineText,companyName,position,phone,name',
                    singleChoice,
                    singleLineText,
                    company_name,
                    job_type,
                    phone,
                    name,
                );

                return;
            }
        });
    };

    const { $bus } = useNuxtApp();
    const submitSuccess = () => {
        message.success(t('global_apply_trial_submit_success'));
        isSubmitting.value = false;
        questionForm.value?.resetFields();
        formState.isFinished = false;
        emits('modalCancel');
    };

    // 校验纷享客是否正常
    const checkFsYxt = () => {
        window.FsYxt.FormSDK.getFieldDescriptions({
            formId: fsYxtFormId,
            success: () => {
                isFsYxtNormal.value = true;
            },
            fail: (err) => {
                console.log('Error', err);
                isFsYxtNormal.value = false;
            },
        });
    };

    const questionForm = ref<FormInstance | null>(null);

    onMounted(() => {
        watch([() => formState.policyConfirm, formState.user], ([value0, value1]) => {
            if (
                value0 === true &&
                Object.keys(value1).filter((key) => {
                    if (key === 'policyConfirm') return false;
                    return !value1[key];
                }).length === 0
            )
                questionForm.value.validate().then(() => {
                    formState.isFinished = true;
                });
            else formState.isFinished = false;
        });
        useFormSDK('8e31b7d9aa0842ebbe616bb78b0f21d7').then((formId) => {
            fsYxtFormId = formId;
            checkFsYxt();
        });
    });
</script>
