<template>
    <section class="default-footer-layout light">
        <CommonComponentsNewsletter v-if="!isChinese" />
        <div class="global-limited-1440px-container default-footer-container" :class="{ 'footer-mobile': isMobile }">
            <div class="default-footer-main">
                <div class="default-footer-main-about">
                    <CommonComponentsSVGCommonLogo />
                    <div class="default-footer-main-mobile-nav">
                        <ACollapse v-model:activeKey="activeKey" :ghost="true" expand-icon-position="right" accordion>
                            <ACollapsePanel
                                v-for="(item, itemIndex) in pageInfo.menu"
                                :key="itemIndex"
                                :header="$t(item.title)"
                            >
                                <ul>
                                    <li
                                        v-for="(subMenuItem, subIndex) in item.submenu"
                                        :key="subIndex"
                                        @click="handleClick(subMenuItem.routePath)"
                                    >
                                        <a>
                                            {{ $t(subMenuItem.linkText) }}
                                        </a>
                                    </li>
                                </ul>
                            </ACollapsePanel>
                        </ACollapse>
                    </div>
                    <div class="default-footer-main-about-info">
                        <div v-if="isChinese">{{ $t(pageInfo.info.address) }}</div>
                        <div v-if="isChinese" class="nowrap">{{ $t(pageInfo.info.tel) }}</div>
                        <div class="nowrap">
                            {{ $t(pageInfo.info.businessEmailTitle) }}
                            <a :href="`mailto:${pageInfo.info.businessEmail}`">{{ pageInfo.info.businessEmail }}</a>
                        </div>
                        <div class="nowrap">
                            {{ $t(pageInfo.info.mediaEmailTitle) }}
                            <a :href="`mailto:${pageInfo.info.mediaEmail}`">{{ pageInfo.info.mediaEmail }}</a>
                        </div>
                    </div>
                    <div v-if="isChinese" class="default-footer-main-about-social">
                        <div class="default-footer-main-about-social-weixin">
                            <CommonComponentsSVGHomeSocialWechat />
                            <div class="default-footer-main-about-social-weixin-QRcode">
                                <img :data-src="pageInfo.QRcode.wechat" alt="" class="png lazy" />
                            </div>
                        </div>
                        <a href="https://www.xiaohongshu.com/user/profile/5c1ce51700000000070313ea" target="_blank">
                            <CommonComponentsSVGHomeSocialLittleRedBook />
                        </a>
                        <a href="https://space.bilibili.com/486904271?spm_id_from=333.337.0.0" target="_blank">
                            <CommonComponentsSVGHomeSocialBilibili />
                        </a>
                        <div class="default-footer-main-about-social-tiktok">
                            <CommonComponentsSVGHomeSocialTiktok />
                            <div class="default-footer-main-about-social-tiktok-QRcode">
                                <img :data-src="pageInfo.QRcode.tiktok" alt="" class="png lazy" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="default-footer-main-about-social">
                        <a href="https://www.linkedin.com/company/style3d/" target="_blank">
                            <CommonComponentsSVGHomeSocialLinkin />
                        </a>
                        <a href="https://www.instagram.com/style3d_official/" target="_blank">
                            <CommonComponentsSVGHomeSocialInstagram />
                        </a>
                        <a href="https://www.youtube.com/channel/UC8AZOc9h-Hp6REu3l5W-4CQ" target="_blank">
                            <CommonComponentsSVGHomeSocialYoutube />
                        </a>
                        <a href="https://twitter.com/Style3D_offical" target="_blank">
                            <CommonComponentsSVGHomeSocialTwitter />
                        </a>
                    </div>
                </div>

                <div class="default-footer-main-links">
                    <div v-for="(item, itemIndex) in pageInfo.menu" :key="itemIndex">
                        <div class="default-footer-main-links-title">
                            {{ $t(item.title) }}
                        </div>
                        <div class="default-footer-main-links-sublink">
                            <a
                                v-for="(subMenuItem, subIndex) in item.submenu"
                                :key="subIndex"
                                @click="handleClick(subMenuItem.routePath)"
                            >
                                {{ $t(subMenuItem.linkText) }}
                            </a>
                            <a @click="showModal" v-if="isChinese && $t(item.title) === $t('global_home_header_menu_4')"
                                >我要反馈</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <ADivider class="default-footer-a-divider" />
            <div class="default-footer-end">
                <div class="default-footer-copyright">
                    {{ $t(pageInfo.copyright) }}
                    <a v-if="isChinese" href="https://beian.miit.gov.cn/#/Integrated/index">
                        {{ pageInfo.recordsCodes }}
                    </a>
                </div>
                <div class="policy-link">
                    <!-- <a @click="newTab(routeMapper.protocol.privacypolicy)">隐私政策</a>
                    <a @click="newTab(routeMapper.protocol.cookie)">Cookie政策</a> -->
                    <a @click="openPolicyFile('service')">{{ $t(pageInfo.policyFile.service) }}</a>
                    <a @click="openPolicyFile('privacy')">{{ $t(pageInfo.policyFile.privacy) }}</a>
                    <a @click="openPolicyFile('cookie')">{{ $t(pageInfo.policyFile.cookie) }}</a>
                </div>
            </div>
        </div>
    </section>
    <div v-if="isVisible">
        <CommonComponentsModalQuestionHelp @modalCancel="modalCancel" />
    </div>
</template>

<script lang="ts" setup>
    import wechatQR from '@assets/imgs/social/newWechatQRcode.png';
    import tiktokQR from '@assets/imgs/social/newTiktokQRcode.png';
    import { onMounted, reactive, ref } from 'vue';
    import { NAV_MAPPER_CN_PC, NAV_MAPPER_EN_PC } from '~~/composables/usePcRoutesMapper';
    import { NAV_MAPPER_CN, NAV_MAPPER_EN } from '~~/composables/useRoutesMapper';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { openPolicyFile } from '~~/composables/usePolicyFile.ts';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { pushRouter } from '~~/composables/useRouterController';

    const isChinese = ifCNLocale();
    const isMobile = ref(false);
    const isVisible = ref(false);

    const showModal = () => {
        isVisible.value = true;
    };
    const modalCancel = () => {
        isVisible.value = false;
    };

    const pageInfo = reactive({
        recordsCodes: '浙ICP备2020033233号',
        policyFile: {
            privacy: 'global_policy_file_privacy',
            cookie: 'global_policy_file_cookie',
            service: 'global_policy_file_service',
        },
        QRcode: {
            wechat: wechatQR,
            tiktok: tiktokQR,
        },
        info: {
            address: 'global_home_footer_info_address',
            tel: 'global_home_footer_info_tel',
            businessEmailTitle: 'global_home_footer_info_businessemail',
            businessEmail: 'sales@style3d.com',
            mediaEmailTitle: 'global_home_footer_info_mediaemail',
            mediaEmail: 'pr@style3d.com',
        },
        menu: [],
        // menu: isChinese ? NAV_MAPPER_CN : NAV_MAPPER_EN,
        copyright: 'global_home_footer_copyright',
    });

    const activeKey = ref(-1);
    onMounted(() => {
        isMobile.value = matchMobile();

        pageInfo.menu = isMobile.value
            ? isChinese
                ? NAV_MAPPER_CN
                : NAV_MAPPER_EN
            : isChinese
              ? NAV_MAPPER_CN_PC
              : NAV_MAPPER_EN_PC;
    });

    const handleClick = (routePath) => {
        pushRouter(routePath);
    };
</script>

<style lang="less" scoped>
    .default-footer-layout {
        position: relative;
        z-index: 50;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        background-color: #000000;
        overflow: hidden;

        // 默认正文字体样式
        font-family: 'HarmonyOS Sans SC';
        font-style: normal;
        font-size: .convert(@font-size-md) [ @vw];
        color: #ffffff;

        // footer 浅色模式
        &.light {
            background-color: #f5f5f5;
            // background-color: rgba(200, 200, 200, 1);
            color: rgba(0, 0, 0, 0.8);
            a {
                color: rgba(0, 0, 0, 0.8);
            }
            // :deep(.svg-social) path {
            //     fill: rgba(0, 0, 0, 0.8);
            // }
            .svg-logo {
                display: block;
                filter: invert(100%);
            }
        }

        .default-footer-container {
            padding: .convert(100px) [ @vw] .convert(80px) [ @vw];

            .default-footer-main {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: .convert(120px) [ @vw];

                .default-footer-main-about {
                    display: flex;
                    flex-direction: column;

                    .svg-logo {
                        width: .convert(166px) [ @vw];
                        height: .convert(42px) [ @vw];
                        @media (max-width: @screen-mobile-width) {
                            width: 80px;
                            height: 20px;
                        }
                    }

                    .default-footer-main-mobile-nav {
                        display: none;
                        // min-height: 450px;
                        & ul > li {
                            & a {
                                color: rgba(0, 0, 0, 0.6);
                            }
                            &:nth-child(n + 1) {
                                margin-top: 12px;
                            }
                        }
                    }

                    .default-footer-main-about-info {
                        margin: @margin-lg 0;

                        & div {
                            width: 100%;
                            white-space: normal;
                            line-height: @line-height-xl;
                        }

                        & .nowrap {
                            white-space: nowrap;
                        }
                    }

                    .default-footer-main-about-social {
                        display: flex;
                        gap: .convert(@flex-gap-xl) [ @vw];

                        .svg-social,
                        .svg-social-icon {
                            width: .convert(36px) [ @vw];
                            height: auto;

                            @media (max-width: @screen-mobile-width) {
                                width: .convert(28px) [ @vw-mobile] !important;
                            }
                        }
                        .default-footer-main-about-social {
                            &-weixin,
                            &-tiktok {
                                position: relative;
                                &-QRcode {
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    width: .convert(110px) [ @vw];
                                    opacity: 0;
                                    transform: translate(-50%, -105%);
                                    scale: 0.2;
                                    transform-origin: 0 0 0;

                                    img {
                                        width: 100%;
                                    }

                                    transition: all 0.2s ease-in-out;
                                    transition-delay: 0.2s;
                                    @media (max-width: @screen-mobile-width) {
                                        width: .convert(90px) [ @vw-mobile];
                                    }
                                }
                                &:hover {
                                    cursor: pointer;
                                    .default-footer-main-about-social-weixin-QRcode,
                                    .default-footer-main-about-social-tiktok-QRcode {
                                        scale: 2.5;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }

                .default-footer-main-links {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    .default-footer-main-links-title {
                        font-size: .convert(@font-size-lg) [ @vw];
                        font-weight: 500;
                        white-space: nowrap;
                        margin-bottom: @margin-lg;
                    }

                    .default-footer-main-links-sublink {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        a {
                            white-space: nowrap;
                        }
                    }
                }
            }

            .default-footer-a-divider {
                margin-top:;
                height: 1px;
                background: rgba(0, 0, 0, 0.2);
                margin: 50px 0 @margin-xs 0;
            }

            .default-footer-end {
                position: relative;
                .default-footer-copyright {
                    position: absolute;
                    left: 0;
                    white-space: nowrap;

                    font-family: HarmonyOS Sans SC;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    a {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                .policy-link {
                    position: absolute;
                    right: 0;
                    display: flex;
                    width: fit-content;
                    gap: .convert(12px) [ @vw];
                    a {
                        line-height: 1.5;
                    }
                }
            }

            &.footer-mobile {
                padding: 25px 20px 28px 20px;

                .default-footer-main {
                    margin: 0;
                    flex-direction: column;

                    &-about {
                        .svg-logo {
                            width: .convert(80px) [ @vw-mobile];
                        }
                        .default-footer-main-mobile-nav {
                            display: block;
                        }

                        :deep(.ant-collapse) {
                            border: none;
                            margin-top: 16px;

                            .ant-collapse-item {
                                color: rgba(0, 0, 0, 0.8);

                                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                &.ant-collapse-item-active {
                                }
                                .ant-collapse-header {
                                    color: rgba(0, 0, 0, 0.8);

                                    padding-left: 0;
                                }

                                .ant-collapse-content {
                                    .ant-collapse-content-box {
                                        padding-top: 8px;
                                        ul {
                                            li {
                                                margin-top: 0;
                                                margin-bottom: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .default-footer-main-about-info {
                            white-space: nowrap;
                            margin-bottom: 15px;
                            font-size: min(.convert(13px) [ @vw-mobile], 12px);
                        }
                        .default-footer-main-about-social {
                            gap: .convert(8px) [ @vw-mobile];
                            .svg-social,
                            .svg-social-icon {
                                width: .convert(20px) [ @vw-mobile];
                                height: auto;
                            }
                        }
                    }

                    .default-footer-main-links {
                        display: none;
                    }
                }
                .default-footer-a-divider {
                    margin-top: 15px;
                }

                .default-footer-end {
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // padding-bottom: 30px;
                    .default-footer-copyright,
                    .policy-link {
                        position: relative;
                        transform: scale(0.83);
                        left: auto;
                        right: auto;
                        color: rgba(0, 0, 0, 0.48);
                        a {
                            color: rgba(0, 0, 0, 0.48);
                        }
                    }
                    .default-footer-copyright {
                        margin: 0;
                    }
                    .policy-link {
                        gap: 12px;
                    }
                }
            }
        }
    }
</style>
